.friends-help {
  display: block;
  padding: 1.2rem 0.32rem;
}

.friends-help .item-alert {
  color: #fff;
  text-align: center;
  font-size: 0.32rem;
  font-weight: 600;
  margin-bottom: 0.48rem;
}

.friends-help .item-alert svg {
  color: #FFB841;
  vertical-align: middle;
  font-size: 0.32rem;
  margin-top: -0.04rem;
}

.friends-help .item-share {
  height: 0.88rem;
  line-height: 0.88rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0.16rem;
  padding: 0 0.24rem;
}

.friends-help .item-share .value {
  width: 85%;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.friends-help .item-share .copy-right {
  width: 0.44rem;
  height: 0.44rem;
  text-align: right;
  cursor: pointer;
  background: url('/public/images/clipboard.png') center no-repeat;
  background-size: contain;
}


.friends-help .inviter {
  list-style: none;
  padding: 0.24rem;
  margin: 0;
  background: #212121;
  margin-top: 0.48rem;
  border-radius: 0.16rem;
}

.friends-help .inviter .title {
  color: #fff;
  padding: 0 0 0.24rem 0;
  border-bottom: 0.01rem solid #B6B6BE;
  text-align: center;
  margin-bottom: 0.24rem;
}

.friends-help .inviter li {
  font-size: 0.28rem;
  color: #B6B6BE;
  margin-bottom: 0.24rem;
  height: 0.44rem;
  line-height: 0.44rem;
  justify-content: space-between;
  display: flex;
}

.friends-help .inviter li svg {
  color: #FFB841;
  vertical-align: middle;
  font-size: 0.32rem;
  margin-top: -0.04rem;
}

.friends-help .inviter li .face {
  width: 0.44rem;
  height: 0.44rem;
  margin-right: 0.24rem;
}

.friends-help .inviter li .nickname {
  width: 50%;
}

.friends-help .inviter li .energy {
  width: 20%;
}

.friends-help .inviter li .points {
  width: 20%;
}

@media only screen and (min-width: 1280px) {
  .friends-help {
    width: 640px;
    margin: 0 auto;
  }

  .friends-help .item-alert {
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
  }

  .friends-help .item-alert svg {
    font-size: 14px;
  }

  .friends-help .item-share {
    height: 48px;
    line-height: 48px;
    border-radius: 8px;
    padding: 0 0px 0 15px;
  }

  .friends-help .item-share .value {
    font-size: 14px;
    width: auto;
  }

  .friends-help .item-share .copy-right {
    width: 48px;
    height: 48px;
    background-size: 40%;
    text-align: left;
  }

  .friends-help .inviter {
    border-radius: 8px;
    padding: 20px;
  }

  .friends-help .inviter .title {
    font-size: 14px;
    padding: 0 0 20px 0;
    margin-bottom: 10px;
  }

  .friends-help .inviter li {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .friends-help .inviter li svg {
    font-size: 16px;
    margin-top: -2px;
  }

  .friends-help .inviter li .face {
    width: 28px;
    height: 28px;
  }

  .friends-help .inviter li .nickname {
    width: 60%;
  }

  .friends-help .inviter li .energy {
    width: 15%;
  }

  .friends-help .inviter li .points {
    width: 15%;
  }
}