.footer {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.footer .li {
    height: 0.48rem;
    font-size: 0.28rem;
    color: #fff;
    letter-spacing: 0.01rem;
    line-height: 0.48rem;
    margin-bottom: 0.24rem;
    cursor: pointer;
    padding: 0 0.48rem;
    text-transform: uppercase;
}

.footer .line {
    height: 0.01rem;
    margin: 0.48rem;
    border-top: 0.01rem dotted #333;
}

.footer .current {
    color: #fff;
    cursor: default;
    font-weight: 600;
    text-decoration-line: underline;

}

.footer .co {
    min-height: 1.4rem;
    font-size: 0.28rem;
    text-align: center;
    word-break: break-all;
    border-top: 0.01rem solid #ffffff33;
    font-weight: 400;
    padding: 0 0.48rem;
    color: #99999f;
    padding-top: 0.48rem;
}

.footer .co-lien {
    padding: 0.24rem 0.48rem;
}

.footer .co-lien .link {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #99999f;
}

.footer .co-lien .smlink {
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #99999f;
}

.footer .co-lien a {
    color: #fff;
    text-decoration: none;
    font-weight: lighter;
}

.footer .co-icon {
    padding: 0.48rem 0;
}

.footer .co-icon .co-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer .co-icon .co-logo {
    text-align: center;
    padding: 0.24rem 0;
}

.footer .co img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 0.32rem;
    margin-bottom: 0.32rem;
}

.footer .co p {
    font-size: 0.28rem;
    margin: 0;
    color: #B6B6BE;
    text-align: left;
    margin-bottom: 0.24rem;
}

.pc-footer {
    display: none;
}

@media only screen and (min-width: 1280px) {
    .footer {
        display: none;
    }

    /* 底部页脚样式 */
    .pc-footer {
        background-color: #000;
        color: white;
        width: 100%;
        padding: 20px 0;
        display: block;
    }

    .pc-footer .footer-container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
    }

    .pc-footer .footer-container .footer-box {
        flex: 1;
        box-sizing: border-box;
    }

    .pc-footer .footer-container .footer-box .link {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        color: #99999f;
    }

    .pc-footer .footer-container .footer-box .smlink {
        padding: 0;
        margin: 0;
        font-size: 14px;
        color: #99999f;
    }

    .pc-footer .footer-container .footer-box a {
        color: #fff;
        text-decoration: none;
        font-weight: lighter;
    }

    .pc-footer .footer-container .footer-icon {
        flex: 2 1;
        box-sizing: border-box;
        display: flex;
    }

    .pc-footer .footer-container .footer-icon .icon-list {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 50%;
    }

    .pc-footer .footer-container .footer-icon .icon-list .ig-logo {
        margin-right: 20px;
    }

    .pc-footer .footer-container .footer-icon .logo-list {
        width: 50%;
        text-align: left;
    }

    .pc-footer .footer-container .footer-box-large {
        flex: 2 1;
        text-align: left;
        color: #99999f;
    }

    .pc-footer .footer-container .footer-box-large p {
        font-size: 14px;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
    }

    .pc-footer .footer-container .footer-box ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .pc-footer .footer-container .footer-box ul li {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
    }

    .pc-footer .footer-container .footer-box ul li:hover {
        color: #B6B6BE;
        cursor: pointer;
    }

    .pc-footer .footer-container .footer-box h3 {
        margin-bottom: 10px;
    }

    .pc-footer .footer-container .footer-rows {
        display: flex;
        align-items: flex-start;
    }
}