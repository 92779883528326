.lottery {
  display: block;
  padding: 1.2rem 0.32rem;
  text-align: center;

}

.lottery .announcement-container {
  height: 1rem;
  overflow: hidden;
  border-radius: 0.16rem;
  position: relative;
  background-color: #000;
  margin-bottom: 0.24rem;
}

.lottery .announcement-container .announcement-list {
  display: flex;
  flex-direction: column;
  position: relative;
  animation: jump-scroll 9s steps(9) infinite;
}

.lottery .announcement-container .announcement-list .announcement-item {
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.32rem;
  font-weight: bold;
}

@keyframes jump-scroll {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-9rem);
  }
}

.lottery .announcement-container .announcement-list .announcement-item svg {
  color: #B6B6BE;
  vertical-align: middle;
  font-size: 0.28rem;
}

.lottery .announcement-container .announcement-list .announcement-item img {
  width: 0.48rem;
  height: 0.48rem;
  border-radius: 0.16rem;
}

.lottery .wheel {
  display: grid;
  grid-template-columns: repeat(3, 2.1rem);
  grid-template-rows: repeat(3, 2.1rem);
  gap: 0.24rem;
  border: none;
  position: relative;
  justify-content: center;
  align-items: center;
}

.lottery .wheel .item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #212121;
  color: #99999f;
  font-size: 0.28rem;
  font-weight: bold;
  border-radius: 0.16rem;
  height: 100%;
  opacity: 1;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.lottery .wheel .item img {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.16rem;
}

@keyframes flash {
  0% {
    opacity: 1;
    transform: scale(1);
    background: linear-gradient(145deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  }

  30% {
    opacity: 0;
    transform: scale(1.2);
    background: linear-gradient(145deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  }

  60% {
    opacity: 1;
    transform: scale(1);
    background: linear-gradient(145deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    background: linear-gradient(145deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  }
}

.lottery .wheel .an-flash {
  animation: flash 1s ease-in-out infinite;
}

.lottery .refresh {
  margin-top: 0.48rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: center;
  margin-bottom: 0.24rem;
  cursor: pointer;
}

.lottery .refresh svg {
  vertical-align: middle;
  font-size: 0.32rem;
  margin-top: -0.04rem;
}

.lottery .refresh .points {
  color: #FFB841;
  vertical-align: middle;
  font-size: 0.24rem;
  padding: 0 0.12rem;
}

.lottery .operation {
  margin-top: 0.48rem;
  margin-bottom: 0.24rem;
}

.lottery .bubble {
  position: relative;
  background-color: #212121;
  color: #fff;
  border-radius: 0.16rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0.24rem;
  cursor: pointer;
}

.lottery .bubble::before {
  content: '';
  position: absolute;
  top: -0.24rem;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0.16rem;
  border-style: solid;
  border-color: transparent transparent #212121 transparent;
}

.lottery .bubble .bubble-desc {
  font-size: 0.28rem;
  width: 80%;
  color: #B6B6BE;
  font-weight: lighter;
}

.lottery .bubble .bubble-desc .icon-arrow-right {
  background: url('/public/images/arrow-right.png') 70% center no-repeat;
  background-size: 0.12rem 0.2rem;
  padding: 0 0.24rem;
}

.lottery .bubble .bubble-imgs {
  width: 1.2rem;
  height: 1.2rem;
  overflow: hidden;
  position: relative;
}

.lottery .bubble .bubble-imgs img {
  width: 1.2rem;
}

.lottery-details {
  display: block;
  text-align: center;
}

.lottery-details .pic {
  width: 2.44rem;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
  margin-bottom: 0.24rem;
  text-align: center;
}

.lottery-details .rows {
  min-height: 0.46rem;
  font-weight: 600;
  font-size: 0.28rem;
  color: #B6B6BE;
  line-height: 0.46rem;
  text-align: center;
  margin-bottom: 0.2rem;
}

@media only screen and (min-width: 1280px) {
  .lottery {
    width: 640px;
    margin: 0 auto;
  }

  .lottery .announcement-container {
    height: 44px;
    line-height: 44px;
    margin-bottom: 20px;
    border-radius: 8px;
  }

  .lottery .announcement-container .announcement-list {
    animation: jump-scroll 9s steps(9) infinite;
  }

  .lottery .announcement-container .announcement-list .announcement-item {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }

  .lottery .announcement-container .announcement-list .announcement-item svg {
    font-size: 14px;
    padding: 0 5px;
  }

  .lottery .announcement-container .announcement-list .announcement-item img {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  @keyframes jump-scroll {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-3.6rem);
    }
  }

  .lottery .wheel {
    gap: 10px;
    grid-template-columns: repeat(3, 205px);
    grid-template-rows: repeat(3, 205px);
  }

  .lottery .wheel .item {
    font-size: 14px;
    border-radius: 16px;
  }

  .lottery .wheel .item img {
    width: 180px;
    height: 180px;
    border-radius: 16px;
  }

  .lottery .refresh {
    font-size: 14px;
  }

  .lottery .refresh svg {
    font-size: 14px;
  }

  .lottery .refresh .points {
    font-size: 14px;
    padding: 0 5px;
  }

  .lottery .operation {
    margin-top: 20px;
  }

  .lottery .bubble {
    padding: 12px;
    border-radius: 8px;
  }

  .lottery .bubble .bubble-desc {
    font-size: 14px;
  }

  .lottery .bubble .bubble-desc .icon-arrow-right {
    background-size: contain;
    padding: 0 10px;
  }

  .lottery .bubble .bubble-imgs {
    width: 64px;
    height: 64px;
  }

  .lottery .bubble .bubble-imgs img {
    width: auto;
  }

  .lottery-details .pic {
    width: 160px;
    margin-bottom: 10px;
    border-radius: 16px;
  }

  .lottery-details .rows {
    margin: 0;
    font-size: 14px
  }
}