.button {
  font-weight: 500;
  font-size: 0.32rem;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  border: none;
}

.button:active {
  opacity: 0.7;
  background: #DC4530;
}

.button svg {
  color: #FFB841;
  vertical-align: middle;
  font-size: 0.32rem;
  margin-top: -0.04rem;
}

.default-button,
.small-default-button {
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  border-radius: 0.16rem;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  cursor: pointer;
}

.lock-button {
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  cursor: pointer;
  background: #888888;
  border-radius: 0.16rem;
  opacity: 0.7;
  pointer-events: none;
}

.small-button {
  width: 1.6rem;
  height: 0.88rem;
  line-height: 0.88rem;
  cursor: pointer;
  background: #B81902;
}

.cancel-button {
  background: #888888;
  padding: 0.3rem 1rem;
  cursor: default;
  text-align: center;
}

@media only screen and (min-width: 1280px) {
  .button {
    font-size: 16px;
    font-style: normal;
  }

  .button svg {
    font-size: 16px;
    margin-top: -2px;
  }

  .default-button {
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
  }

  .small-default-button {
    width: 400px;
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
    background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
    cursor: pointer;
  }

  .lock-button {
    height: 44px;
    line-height: 44px;
    border-radius: 8px;
  }
}