.modal-layer {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  z-index: 2;
  margin: 0 auto;
  backdrop-filter: blur(0.1rem);
}

.modal-content {
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.modal-content .modal-default {
  width: 90%;
  height: auto;
  background: #111111;
  border-radius: 0.32rem;
  backdrop-filter: blur(0.1rem);
  box-shadow: 0 0 0.1rem 0 rgba(255, 255, 255, 0.5);
}

.modal-content .modal-default .head {
  padding: 0.4rem;
  height: 0.64rem;
  line-height: 0.64rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -1.2rem;
}

.modal-content .modal-default .head .icon-close {
  background: rgba(255, 255, 255, 0.18) url("/public/images/close.png") center no-repeat;
  width: 0.64rem;
  height: 0.64rem;
  background-size: 60%;
  cursor: pointer;
  border-radius: 50%;
}

.modal-content .modal-default .body {
  padding: 0.4rem;
}

.modal-content .modal-alert {
  width: 92%;
  height: auto;
  background: #111111;
  box-shadow: 0 0 0.1rem 0 rgba(255, 255, 255, 0.5);
  border-radius: 0.32rem;
  backdrop-filter: blur(0.1rem);
  min-height: 4rem;
}

.modal-content .modal-alert .head {
  height: 1.6rem;
  line-height: 1.6rem;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: -0.8rem;
}

.modal-content .modal-alert .head .icon-alert {
  background: #999 url("/public/images/close.png") center no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  background-size: cover;
  cursor: pointer;
  border-radius: 50%;
}

.modal-content .modal-alert .head .icon-voting {
  background: url("/public/images/voting.png") center no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  background-size: cover;
}

.modal-content .modal-alert .head .icon-votes {
  background: url("/public/images/votes.png") center no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  background-size: cover;
}

.modal-content .modal-alert .head .icon-pay {
  background: url("/public/images/pay.png") center no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  background-size: cover;
}

.modal-content .modal-alert .head .icon-failed {
  background: url("/public/images/failed.png") center no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  background-size: cover;
}

.modal-content .modal-alert .head .icon-notification {
  background: url("/public/images/notification.png") center no-repeat;
  width: 1.6rem;
  height: 1.6rem;
  background-size: cover;
}

.modal-content .modal-alert .body {
  padding: 0 0.24rem 0.24rem;
}

@media only screen and (min-width: 1280px) {
  .modal-layer {
    width: 100%;
    z-index: 3;
  }

  .modal-content {
    width: 100%;
  }

  .modal-content .modal-default {
    width: 600px;
    border-radius: 16px;
  }

  .modal-content .modal-default .head {
    height: 40px;
    line-height: 40px;
    padding: 10px;
    margin-top: -60px;
  }

  .modal-content .modal-default .head .icon-close {
    height: 40px;
    width: 40px;
  }

  .modal-content .modal-default .body {
    padding: 20px;
  }

  .modal-content .modal-alert {
    width: 600px;
    min-height: auto;
  }

  .modal-content .modal-alert .head {
    height: 80px;
    line-height: 80px;
    margin-top: -40px;
  }

  .modal-content .modal-alert .head .icon-pay {
    width: 80px;
    height: 80px;
  }

  .modal-content .modal-alert .head .icon-alert {
    width: 80px;
    height: 80px;
  }

  .modal-content .modal-alert .head .icon-voting {
    width: 80px;
    height: 80px;
  }

  .modal-content .modal-alert .head .icon-votes {
    width: 80px;
    height: 80px;
  }

  .modal-content .modal-alert .head .icon-failed {
    width: 80px;
    height: 80px;
  }

  .modal-content .modal-alert .head .icon-notification {
    width: 80px;
    height: 80px;
  }

  .modal-content .modal-alert .body {
    padding: 0 20px 20px;
  }
}