.game-details {
  display: block;
  padding: 1.2rem 0.32rem;
}

.game-details .pic {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
}

.game-details .pic img {
  width: 100%;
  height: auto;
  box-shadow: 0 0.02rem 0.2rem 0 #1d1d1d;
  border-radius: 0.32rem;
}

.game-details .li {
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: center;
  margin-bottom: 0.24rem;
}

.game-details .cmd {
  margin-top: 0.48rem;
  display: block;
}

.game-details .history {
  list-style: none;
  padding: 0.24rem;
  margin: 0;
  background: #212121;
  margin-top: 0.48rem;
  border-radius: 0.16rem;
}

.game-details .history .title {
  color: #fff;
  padding: 0 0 0.24rem 0;
  border-bottom: 0.01rem solid #B6B6BE;
  text-align: center;
  margin-bottom: 0.24rem;
}

.game-details .history li {
  font-size: 0.28rem;
  color: #B6B6BE;
  margin-bottom: 0.24rem;
  height: 0.44rem;
  line-height: 0.44rem;
  justify-content: space-between;
  display: flex;
}

.game-details .history li svg {
  color: #FFB841;
  vertical-align: middle;
  font-size: 0.32rem;
  margin-top: -0.04rem;
}

.game-details .facebook-share-button {
  height: 0.8rem;
  line-height: 0.8rem;
  background: url('/public/images/facebook.png') 10% center no-repeat;
  background-size: 8%;
  color: #fff;
  cursor: pointer;
  text-align: center;
  font-size: 0.28rem;
  border-radius: .16rem;
  margin: 0.48rem auto;
  border: 0.01rem solid #fff;
}

@media only screen and (min-width: 1280px) {
  .game-details {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
    text-align: center;
  }

  .game-details .pic {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .game-details .pic img {
    width: 300px;
    height: auto;
    border-radius: 16px;
  }

  .game-details .li {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .game-details .cmd {
    margin-top: 20px;
    display: block;
  }

  .game-details .history {
    border-radius: 16px;
    padding: 20px;
  }

  .game-details .history .title {
    font-size: 14px;
    padding: 0 0 20px 0;
    margin-bottom: 10px;
  }

  .game-details .history li {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
  }

  .game-details .history li svg {
    font-size: 16px;
    margin-top: -2px;
  }

  .game-details .facebook-share-button {
    width: 400px;
    height: 44px;
    line-height: 44px;
    background: url('/public/images/facebook.png') 15% center no-repeat;
    background-size: 25px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    border: 1px solid #fff;
    margin: 20px auto;
  }

}