.prize-claim {
  display: block;
  padding: 1.2rem 0.32rem;
}

.prize-claim .tags-bar {
  height: 0.68rem;
  line-height: 0.68rem;
  margin-bottom: 0.48rem;
}

.prize-claim .tags-bar .tags-list {
  display: flex;
}

.prize-claim .tags-bar .tags-list .tag {
  min-width: 1.5rem;
  height: 0.68rem;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 0.32rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  line-height: 0.68rem;
  margin-right: 0.2rem;
  text-align: center;
  cursor: pointer;
  padding: 0 0.24rem;
}

.prize-claim .tags-bar .tags-list .chk {
  min-width: 1.5rem;
  height: 0.68rem;
  border-radius: 0.32rem;
  line-height: 0.68rem;
  text-align: center;
  margin-right: 0.2rem;
  background: linear-gradient(180deg, #F6D689 0%, #BB8C30 100%);
  font-weight: 600;
  font-size: 0.28rem;
  color: #3D230D;
  cursor: default;
  padding: 0 0.24rem;
}

.prize-claim .list {
  padding: 0;
  list-style: none;
  margin: 0;
}

.prize-claim .list li {
  background: #212121;
  border-radius: 0.32rem;
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 0.24rem;
  padding: 0.2rem 0;
}

.prize-claim .list li .cover {
  width: 26%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.prize-claim .list li .cover img {
  width: 1.4rem;
  height: 1.4rem;
  box-shadow: 0 0.14rem 0.34rem 0.04rem #88888833;
  border-radius: 0.16rem;
  object-fit: cover;
}

.prize-claim .list li .lotterys {
  width: 74%;
  font-size: 0.24rem;
  color: #B6B6BE;
}

.prize-claim .list li .lotterys .title {
  height: 0.8rem;
  font-weight: 500;
  color: #fff;
  line-height: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.28rem;
}

.prize-claim .list li .lotterys .operation {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 0.4rem;
  line-height: 0.4rem;
}

.prize-claim .nodata {
  color: #B6B6BE;
  text-align: center;
  font-size: 0.28rem;
}

/* PC端样式: 当屏幕宽度最小为1280px时应用 */
@media only screen and (min-width: 1280px) {
  .prize-claim {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
  }

  .prize-claim .tags-bar {
    height: 44px;
    line-height: 44px;
    margin-bottom: 20px;
  }

  .prize-claim .tags-bar .tags-list {
    display: flex;
  }

  .prize-claim .tags-bar .tags-list .tag {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    min-width: auto;
    margin-right: 10px;
  }

  .prize-claim .tags-bar .tags-list .chk {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    min-width: auto;
  }

  .prize-claim .list li {
    margin-bottom: 20px;
    border-radius: 16px;
    padding: 10px 0;
  }

  .prize-claim .list li .cover {
    width: 14%;
  }

  .prize-claim .list li .cover img {
    width: 72px;
    height: 72px;
  }

  .prize-claim .list li .lotterys {
    width: 86%;
    font-size: 14px;
  }

  .prize-claim .list li .lotterys .title {
    height: 34px;
    line-height: 34px;
    font-size: 14px;
  }

  .prize-claim .list li .lotterys .operation {
    height: 30px;
    line-height: 30px;
  }

  .prize-claim .nodata {
    font-size: 14px;
  }

}