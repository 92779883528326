.my-1game-account {
  display: block;
  padding: 1.2rem 0.32rem;
}

.my-1game-account .item-rows {
  min-height: 0.88rem;
  line-height: 0.88rem;
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: center;
}

.my-1game-account .item-rows .face {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 auto;
}

.my-1game-account .item-text {
  font-weight: 500;
  font-size: 0.28rem;
  color: #fff;
  text-align: left;
  margin-top: 0.24rem;
}

.my-1game-account .item {
  height: 0.88rem;
  line-height: 0.88rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #212121;
  padding: 0 0.24rem 0 0.24rem;
  margin-top: 0.24rem;
  border-radius: 0.16rem;
}

.my-1game-account .item .value {
  width: 85%;
  font-weight: 500;
  font-size: 0.28rem;
  color: #B6B6BE;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-1game-account .item .value svg {
  color: #FFB841;
  vertical-align: middle;
  font-size: 0.32rem;
  margin-top: -0.04rem;
}

.my-1game-account .item .copy-right {
  width: 0.44rem;
  height: 0.44rem;
  text-align: right;
  cursor: pointer;
  background: url('/public/images/clipboard.png') center no-repeat;
  background-size: contain;
}

@media only screen and (min-width: 1280px) {
  .my-1game-account {
    width: 640px;
    margin: 0 auto;
    min-height: 50%;
  }

  .my-1game-account .item-rows {
    font-size: 14px;
    min-height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .my-1game-account .item-rows .face {
    width: 72px;
    height: 72px;
  }

  .my-1game-account .item-text {
    font-size: 14px;
  }

  .my-1game-account .item {
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 0 0 0 15px;
    margin-top: 15px;
  }

  .my-1game-account .item .value {
    font-size: 14px;
    width: auto;
  }

  .my-1game-account .item .value svg {
    vertical-align: middle;
    font-size: 16px;
    margin-top: -4px;
  }

  .my-1game-account .item .copy-right {
    width: 48px;
    height: 48px;
    background-size: 40%;
    text-align: left;
  }

}