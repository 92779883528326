.game-list {
  display: block;
  min-height: 100vh;
  padding: 1.2rem 0.32rem;
}

.game-list .games-list {
  margin-top: 0.24rem;
}

.game-list .games-list .li {
  min-height: 2.64rem;
  margin-bottom: 0.24rem;
}

.game-list .games-list .li:last-child {
  margin-bottom: 0;
}

.game-list .games-list .li .count {
  height: 0.46rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.46rem;
  text-align: left;
  margin-bottom: 0.24rem;
}

.game-list .games-list .li .cover-list {
  min-height: 1.98rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
  text-align: center;
}

.game-list .games-list .li .cover-list .game {
  cursor: pointer;
}

.game-list .games-list .li .cover-list .game img {
  width: 2.15rem;
  height: 2.15rem;
  box-shadow: 0 0.14rem 0.34rem 0.04rem rgba(136, 136, 136, 0.2);
  border-radius: 0.16rem;
  object-fit: contain;
}

.game-list .games-list .li .cover-list .game .game-energy {
  height: 0.46rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.46rem;
  text-align: left;
  margin-bottom: 0.12rem;
  text-indent: 0.24rem;
  background: rgba(0, 0, 0, 0.7);
  position: relative;
  margin-top: -0.53rem;
  border-radius: 0 0 0.16rem 0.16rem;
}

.game-list .games-list .li .cover-list .game .game-energy svg {
  font-size: 0.24rem;
  color: #FFB841;
}

.game-list .games-list .li .cover-list .game .game-name {
  height: 0.46rem;
  font-weight: 600;
  font-size: 0.32rem;
  color: #fff;
  line-height: 0.46rem;
  text-align: center;
  margin-bottom: 0.24rem;
}

@media only screen and (min-width: 1280px) {
  .game-list {
    width: 1200px;
    margin: 0 auto;
    min-height: 50%;
  }

  .game-list .games-list {
    margin-top: 20px;
  }

  .game-list .games-list .li .count {
    font-size: 16px;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;
  }

  .game-list .games-list .li .cover-list .game img {
    width: 180px;
    height: 180px;
    border-radius: 8px;
  }

  .game-list .games-list .li .cover-list .game .game-energy {
    font-size: 14px;
    margin-bottom: 0;
    height: 40px;
    line-height: 40px;
    background: rgba(0, 0, 0, 0.7);
    position: relative;
    margin-top: -43px;
    border-radius: 0 0 8px 8px;
  }

  .game-list .games-list .li .cover-list .game .game-energy svg {
    font-size: 12px;
  }

  .game-list .games-list .li .cover-list .game .game-name {
    font-size: 14px;
    margin-bottom: 10px;
    height: 40px;
    line-height: 40px;
  }


}