.maintenance {
    display: block;
    padding: 1.2rem 0.32rem;
}

.maintenance h1 {
    text-align: center;
    font-size: 0.32rem;
    color: #fff;
}

.maintenance p {
    padding: 0.24rem;
    background: #111;
    color: #b6b6be;
    text-align: center;
}


@media only screen and (min-width: 1280px) {
    .maintenance {
        width: 640px;
        margin: 0 auto;
        min-height: 50%;
    }

    .maintenance h1 {
        font-size: 16px;

    }

    .maintenance p {
        font-size: 14px;

    }
}