html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #111;
  font-family: Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
}

div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app {
  height: 100vh;
  width: 7.5rem;
  display: block;
  margin: 0 auto;
  overflow: auto;
}

.input {
  width: 93%;
  height: 0.88rem;
  background: #212121;
  border-radius: 0.16rem;
  border: none;
  font-weight: 400;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.88rem;
  text-align: left;
  padding: 0 0.24rem;
  transition: border-color 0.3s ease;
  border-color: #F04438;
  outline: none;
}

.select {
  width: 100%;
  height: 0.88rem;
  background: #212121;
  border-radius: 0.16rem;
  border: none;
  font-weight: 400;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.88rem;
  text-align: left;
  padding: 0 0.24rem;
  transition: border-color 0.3s ease;
  border-color: #F04438;
  outline: none;
  margin-bottom: 0.48rem;
}

.textarea {
  width: 93%;
  min-height: 2rem;
  background: #212121;
  border-radius: 0.16rem;
  border: none;
  font-weight: 400;
  font-size: 0.32rem;
  color: #ffffff;
  line-height: 0.44rem;
  text-align: left;
  padding: 0.24rem;
  transition: border-color 0.3s ease;
  border-color: #F04438;
  outline: none;
}

.facebook-share-diglog {
  display: block;
}

.facebook-share-diglog .share-title {
  color: #fff;
  font-size: 0.28rem;
  font-weight: 500;
  margin-bottom: 0.24rem;
  margin-top: 0.24rem;
  text-align: left;
}

.facebook-share-diglog .share-quote {
  background: #212121;
  border-radius: 0.16rem;
  color: #B6B6BE;
  font-size: 0.28rem;
  font-style: normal;
  padding: 0.32rem;
  text-align: left;
  margin-bottom: 0.24rem;
}

.facebook-share-diglog .share-quote a {
  color: #fff;
}

.facebook-share-diglog .share-button {
  text-align: center;
}

.facebook-share-diglog .flex-button {
  display: flex;
  justify-content: space-between;
}

.facebook-share-diglog .share-button .yes {
  width: 2.45rem;
  height: 0.88rem;
  background: linear-gradient(90deg, #FF9C66 0%, #DC4530 47%, #97180C 100%);
  border-radius: 0.16rem;
  color: #fff;
  line-height: 0.8rem;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 0.32rem;
}

.facebook-share-diglog .share-button .no {
  width: 2.45rem;
  height: 0.88rem;
  line-height: 0.8rem;
  background: #3F3F42;
  border-radius: 0.16rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  font-size: 0.32rem;
}

@media only screen and (min-width: 1280px) {
  .app {
    width: 100%;
  }

  .facebook-share-diglog {
    display: block;
  }

  .facebook-share-diglog .share-title {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .facebook-share-diglog .share-quote {
    border-radius: 8px;
    font-size: 14px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .input {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    border-radius: 8px;
  }

  .select {
    width: 680px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
    padding: 0 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" class="bi bi-caret-down-fill" viewBox="0 0 16 16"><path d="M7.247 11.14 2.451 6.342C2.06 5.951 2.293 5.293 2.854 5.293h10.292c.561 0 .794.658.403 1.049l-4.796 4.796a1 1 0 0 1-1.414 0z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
  }

  .textarea {
    min-height: 60px;
    font-size: 14px;
    padding: 0 10px;
    width: 100%;
    border-radius: 8px;
  }

}